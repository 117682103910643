<template>

	<div>
		<van-nav-bar class="navbar" title="我的积分" fixed left-arrow @click-left="leftReturn" />
		<!-- 最上方橙色 -->
		<div class="header">
			<img class="cbg" src="../../../assets/images/cbg.png" alt="">
			<img class="decoration" src="../../../assets/images/decoration.png" alt="">
			<div class="nowPoints">当前积分</div>
			<div class="num-lv">
				<span class="num">{{num}}</span>
				<span class="lv">·{{lv}}</span>
			</div>
			<div class="detail"> <span class="det">今日获得{{numTwo}}积分，离下个等级{{numOne}}分</span> </div>
			<div class="progress-box">
				<van-progress :percentage="percent" color="#fde784" :show-pivot="false" stroke-width="5" track-color="#feb098" />
			</div>
		</div>
		<!-- 中间引号图片 -->
		<div class="img_group">
			<img src="../../../assets/images/head-quotation-mark.png" class="oneImg">
			<div class="setPsd">积分明细</div>
		</div>

		<!-- 下方列表 -->
		<div class="bottom" v-for=" (item,i) in list " :key="i">
			<div class="lineOne">
				<div class="title">{{item.flowDescription}}</div>
				<div v-if="item.flowIntegral >= 0" class="positivemoney">+{{item.flowIntegral}}</div>
				<div v-if="item.flowIntegral < 0" class="negativemoney">{{item.flowIntegral}}</div>
			</div>
		</div>


		<div class="footer"> - 已经到底了 - </div>
	</div>

</template>

<script>
	import {
		NavBar,
		Progress,
		List,
		PullRefresh,
		Popup,
	} from "vant";
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(Progress);
	import {
		queryBaseData,
		userIntegral
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				num: "",
				lv: "",
				numOne: 3,
				numTwo: 21,
				percent: 80,
				list: [],
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		created() {
			this.initData();
			this.userIntegral();
		},
		//初始化
		mounted() {},
		methods: {
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			//租约变更详情初始化接口
			userIntegral: function() {
				const that = this
				let initData = {}
				initData.user_id = globaluserId()
				userIntegral(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						var currentIntegral =Number(response.data.data.currentIntegral)
						var updateNeedIntegral = Number(response.data.data.updateNeedIntegral)
						var sum = currentIntegral+updateNeedIntegral
						
						that.num= response.data.data.currentIntegral,//目前积分
						that.lv= response.data.data.integralLevelName,//积分等级名称
						that.numOne= response.data.data.updateNeedIntegral,//升级还需积分
						that.numTwo= response.data.data.todayIntegral,//今日获取积分
						that.list= response.data.data.integralFlowList
						that.percent = Number(response.data.data.currentIntegral/sum*100)
						// console.log(that.percent)
					})
				})
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},

		},

	}
</script>

<style lang="less" scoped>
	.navbar {
		/* background-color: white; */
		/* border-bottom: 2px solid white; */
	}

	.header {
		position: relative;
		height: 130px;
		width: 90%;
		margin: 0 auto;
		margin-top: 70px;
		background: linear-gradient(to right, rgb(255, 201, 134), rgb(255, 107, 71));
		border-radius: 15px;
	}

	.cbg {
		position: absolute;
		height: 130px;
		width: 100%;
	}

	.decoration {
		position: absolute;
		width: 37px;
		height: 50px;
		right: 14px;
		top: 12px;
	}

	.nowPoints {
		padding-top: 25px;
		padding-left: 20px;
		color: rgb(75, 11, 14);
		font-weight: 700;
		font-size: 12px;
		font-family: PingFangSC;
	}

	.num-lv {
		height: 30px;
	}

	.num {
		float: left;
		padding-left: 20px;
		color: rgba(74, 13, 13, 1);
		font-weight: 900;
		line-height: 27px;
		font-size: 25px;
		font-family: Oswald;
	}

	.lv {
		float: left;
		color: rgba(74, 13, 13, 1);
		font-weight: 900;
		line-height: 27px;
		font-size: 22px;
		font-family: PingFangSC;
	}

	.detail {
		height: 20px;
		width: 100%;
		/* margin-top: 10px; */
	}

	.det {
		float: right;
		color: rgb(75, 11, 14);
		font-size: 10px;
		font-weight: 700;
		padding-right: 25px;
		font-family: PingFangSC;
	}

	.progress-box {
		width: 90%;
		margin: 0 auto;
	}

	.img_group {
		height: 75px;
		margin: 25px 0px;
		margin-bottom: 0px;
	}

	.setPsd {
		width: 112px;
		height: 40px;
		color: rgba(34, 34, 34, 1);
		font-size: 20px;
		text-align: left;
		font-family: PingFangSC-Semibold;
		position: relative;
		font-weight: bold;
		left: 24px;
		bottom: 35px;
	}

	.oneImg {
		width: 52px;
		height: 57px;
	}

	.bottom {
		background: white;
		width: 100%;
		height: 50px;
		margin-bottom: 1px;
		border-bottom: 1px solid rgb(239, 239, 239);
	}

	.footer {
		margin-top: 15px;
		font-size: small;
		color: rgb(177, 177, 177);
		text-align: center;
	}

	.lineOne {
		padding-top: 15px;
		height: 30px;
		/* background: ghostwhite; */
	}

	.title {
		max-width: 180px;
		margin-left: 17px;
		font-weight: 600;
		float: left;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		overflow: hidden;
		/*超出部分隐藏*/
		text-overflow: ellipsis;
		/*文字超出部分以省略号显示*/
		font-size: 14px;
		font-family: PingFangSC;
	}

	.positivemoney {
		margin-right: 17px;
		float: right;
		font-weight: 700;
		color: rgb(253, 95, 56);
		font-family: Oswald;
		font-size: 15px;
	}

	.negativemoney {
		margin-right: 17px;
		float: right;
		font-weight: 700;
		font-family: Oswald;
		font-size: 15px;
	}
</style>
